import { render, staticRenderFns } from "./newStoreTable.vue?vue&type=template&id=767b2516&scoped=true"
import script from "./newStoreTable.vue?vue&type=script&lang=js"
export * from "./newStoreTable.vue?vue&type=script&lang=js"
import style0 from "./newStoreTable.vue?vue&type=style&index=0&id=767b2516&prod&scoped=true&lang=scss&ref=stylesheet%2Fscss"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "767b2516",
  null
  
)

export default component.exports