<template>
  <div class="phoneTable">
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        /* height: 32px; */
        margin: 16px 0 20px 0;
        margin-bottom: 10px;
      "
    >
      <div style="margin-right: 20px;margin-bottom: 10px;">
        <el-select
          v-if="staffType == '01'"
          size="small"
          v-model="formData.xcxStaffId"
          placeholder="请选择总监/区域"
          clearable
        >
          <el-option
            v-for="item in partition"
            :key="item.name"
            :value="item.staffId"
            :label="
              item.areaName
                ? `${item.staffName}-${item.areaName}`
                : item.staffName
            "
          >
          </el-option>
        </el-select>
        <el-select
          style="margin-left: 10px"
          v-model="formData.storeId"
          placeholder="请选择所属门店"
          size="small"
          clearable
          filterable
        >
          <el-option
            v-for="item in storeList"
            :key="item.key"
            :value="item.key"
            :label="item.value"
          />
        </el-select>
        <span style="margin-left: 10px;font-size: 13px">换购状态:</span>
        <el-select
          v-model="formData.isRenewProductOrder"
          placeholder="请选择"
          size="small"
          clearable
        >
          <el-option :value="false" label="未换购" />
          <el-option :value="true" label="已换购" />
        </el-select>
      </div>
      <div style="display: flex;margin-bottom: 10px;">
        <el-button
          :type="dateType == '01' ? 'primary' : 'default'"
          size="small"
          @click="ClickDateType('01')"
          >今日</el-button
        >
        <el-button
          :type="dateType == '03' ? 'primary' : 'default'"
          size="small"
          @click="ClickDateType('03')"
          >本周</el-button
        >
        <el-button
          :type="dateType == '05' ? 'primary' : 'default'"
          size="small"
          @click="ClickDateType('05')"
          >本月</el-button
        >
      </div>
      <div class="el-date-time" style="margin-right: 0;margin-bottom: 10px;">
        <el-date-picker
          style="flex: 1"
          v-model="valueTime"
          type="daterange"
          value-format="timestamp"
          size="small"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </div>
      <span
        style="
          color: #20a0ff;
          font-size: 14px;
          margin-left: 20px;
          cursor: pointer;
          margin-bottom: 10px;
        "
        @click="addTimePicker"
        >{{ addTimeText }}</span
      >
      <div class="el-date-time" style="margin-right: 0;margin-bottom: 10px;">
        <el-date-picker
          style="flex: 1"
          v-model="thanValueTime"
          type="daterange"
          value-format="timestamp"
          size="small"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          v-if="addTime"
          @change="handleCurrentChange"
        >
        </el-date-picker>
      </div>
      <span style="margin-bottom: 10px;" @click="addTimeCancle" v-if="addTime == true"
        ><img
          src="../../../../assets/images/home/-s-zy_ico_close.png"
          class="close_img"
      /></span>
      <div>
        <el-button
          type="primary"
          size="small"
          style="margin-left: 20px"
          :loading="searchLoading"
          @click="handleCurrentChange"
          ><i class="el-icon-search"></i>查询</el-button
        >
      </div>
        <el-button
        style="margin-left: 20px;"
          size="small"
          type="success"
          icon="el-icon-download"
          :loading="exportExcelLoading"
          @click="exportExcel()"
          >导出Excel</el-button
        >
    </div>
    <el-table
      max-height="450"
      :data="tableData"
      v-loading="phoneTableLoading"
      style="width: 100%"
      border
    >
      <template slot="empty">
        <div class="table_empty">
          <img
            src="../../../../assets/images/tjsjtb.png"
            style="width: 200px; height: 100px"
            alt=""
          />
          <span style="font-size: 12px; line-height: 12px; padding-left: 12px"
            >暂无数据</span
          >
        </div>
      </template>
      <el-table-column label="排序  " type="index" align="center" width="70">
        <template slot-scope="{ $index }">
          <div class="numBG_1" v-if="$index === 0">1</div>
          <div class="numBG_2" v-else-if="$index === 1">2</div>
          <div class="numBG_3" v-else-if="$index === 2">3</div>
          <div v-else>{{ $index + 1 }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="storeName"
        align="center"
        label="门店"
        width="180px"
      >
      </el-table-column>
      <!-- <el-table-column 
        prop="storeName"
        align="center" 
        label="所属门店"
        width="180px"
      >
      </el-table-column> -->
      <el-table-column
        prop="inquiryNum.chooseData"
        label="询价量"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <span>{{ scope.row.inquiryNum.chooseData }}</span>
          <div v-if="tableSHowThan == true">
            <div
              v-if="scope.row.inquiryNum.thanUp == '00'"
              class="equationBlue"
            >
              <img
                src="../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ scope.row.inquiryNum.thanData }}%
            </div>
            <div
              v-else-if="scope.row.inquiryNum.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.inquiryNum.thanData }}%
            </div>
            <div
              v-else-if="scope.row.inquiryNum.thanUp == '02'"
              class="equationRed"
            >
              <img
                src="../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.inquiryNum.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="finishNum.chooseData" align="center" sortable>
        <template slot="header">
          <div class="img-el-heard">
            <span>完成录音(单)</span>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="询价订单中完成录音的订单量"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.finishNum.chooseData }}</span>
          <div v-if="tableSHowThan == true">
            <div v-if="scope.row.finishNum.thanUp == '00'" class="equationBlue">
              <img
                src="../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ scope.row.finishNum.thanData }}%
            </div>
            <div
              v-else-if="scope.row.finishNum.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.finishNum.thanData }}%
            </div>
            <div
              v-else-if="scope.row.finishNum.thanUp == '02'"
              class="equationRed"
            >
              <img
                src="../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.finishNum.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="normalCount.chooseData"
        align="center"
        label="有效录音(单)"
        sortable
      >
        <template slot-scope="scope">
          <span>{{ scope.row.normalCount.chooseData }}</span>
          <div v-if="tableSHowThan == true">
            <div
              v-if="scope.row.normalCount.thanUp == '00'"
              class="equationBlue"
            >
              <img
                src="../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ scope.row.normalCount.thanData }}%
            </div>
            <div
              v-else-if="scope.row.normalCount.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.normalCount.thanData }}%
            </div>
            <div
              v-else-if="scope.row.normalCount.thanUp == '02'"
              class="equationRed"
            >
              <img
                src="../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.normalCount.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="normalRatio.chooseData"
        label="有效录音占比"
        align="center"
        sortable
      >
        <template slot="header">
          <div class="img-el-heard">
            <div>有效录音占比</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="有效录音/完成录音*100%"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.normalRatio.chooseData }}</span>
          <div v-if="tableSHowThan == true">
            <div
              v-if="scope.row.normalRatio.thanUp == '00'"
              class="equationBlue"
            >
              <img
                src="../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ scope.row.normalRatio.thanData }}%
            </div>
            <div
              v-else-if="scope.row.normalRatio.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.normalRatio.thanData }}%
            </div>
            <div
              v-else-if="scope.row.normalRatio.thanUp == '02'"
              class="equationRed"
            >
              <img
                src="../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.normalRatio.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="invalidCount.chooseData" align="center" sortable>
        <template slot="header">
          <div class="img-el-heard">
            <div>无效录音(单)</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="评价为无效录音的订单量"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.invalidCount.chooseData }}</span>
          <div v-if="tableSHowThan == true">
            <div
              v-if="scope.row.invalidCount.thanUp == '00'"
              class="equationBlue"
            >
              <img
                src="../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ scope.row.invalidCount.thanData }}%
            </div>
            <div
              v-else-if="scope.row.invalidCount.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.invalidCount.thanData }}%
            </div>
            <div
              v-else-if="scope.row.invalidCount.thanUp == '02'"
              class="equationRed"
            >
              <img
                src="../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.invalidCount.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="fineCount.chooseData" align="center" sortable>
        <template slot="header">
          <div class="img-el-heard">
            <div>优秀录音(单)</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="评价为优秀录音的订单量"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.fineCount.chooseData }}</span>
          <div v-if="tableSHowThan == true">
            <div v-if="scope.row.fineCount.thanUp == '00'" class="equationBlue">
              <img
                src="../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ scope.row.fineCount.thanData }}%
            </div>
            <div
              v-else-if="scope.row.fineCount.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.fineCount.thanData }}%
            </div>
            <div
              v-else-if="scope.row.fineCount.thanUp == '02'"
              class="equationRed"
            >
              <img
                src="../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.fineCount.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="qualifiedCount.chooseData" align="center" sortable>
        <template slot="header">
          <div class="img-el-heard">
            <div>合格录音(单)</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="评价为合格录音的订单量"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.qualifiedCount.chooseData }}</span>
          <div v-if="tableSHowThan == true">
            <div
              v-if="scope.row.qualifiedCount.thanUp == '00'"
              class="equationBlue"
            >
              <img
                src="../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ scope.row.qualifiedCount.thanData }}%
            </div>
            <div
              v-else-if="scope.row.qualifiedCount.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.qualifiedCount.thanData }}%
            </div>
            <div
              v-else-if="scope.row.qualifiedCount.thanUp == '02'"
              class="equationRed"
            >
              <img
                src="../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.qualifiedCount.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="unqualifiedCount.chooseData"
        align="center"
        sortable
      >
        <template slot="header">
          <div class="img-el-heard">
            <div>不合格录音(单)</div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                content="评价为不合格录音的订单量"
                placement="top"
              >
                <img
                  src="../../../../assets/images/home/ico_ts_red_.png"
                  width="14px"
                  height="14px"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.unqualifiedCount.chooseData }}</span>
          <div v-if="tableSHowThan == true">
            <div
              v-if="scope.row.unqualifiedCount.thanUp == '00'"
              class="equationBlue"
            >
              <img
                src="../../../../assets/images/home/951@2x.png"
                style="width: 8px"
              />{{ scope.row.unqualifiedCount.thanData }}%
            </div>
            <div
              v-else-if="scope.row.unqualifiedCount.thanUp == '01'"
              class="equationGreen"
            >
              <img
                src="../../../../assets/images/home/icon_shqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.unqualifiedCount.thanData }}%
            </div>
            <div
              v-else-if="scope.row.unqualifiedCount.thanUp == '02'"
              class="equationRed"
            >
              <img
                src="../../../../assets/images/home/icon_xjqs_@2x.png"
                style="width: 8px"
              />{{ scope.row.unqualifiedCount.thanData }}%
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import _api from "@/utils/request";
import { MessageBox } from "element-ui";
import { mapState } from "vuex";

export default {
  name: "newStoreTable",
  // props: ["xcxStaffId"],
  props: ["partition","id"],
  data() {
    return {
      phoneTableLoading: false,
      valueTime: "",
      thanValueTime: [],
      orderType: "01",
      dateType: "01",
      tableData: [],
      formData: {
        xcxStaffId: "",
        storeId: "",
        isRenewProductOrder: "",
      },
      addTime: false,
      addTimeText: "+添加对比时间",
      isThan: false,
      tableSHowThan: true,
      searchLoading: false,
      exportExcelLoading: false
    };
  },
  created() {
    // console.log(this.xcxStaffId, "父传子");
    this.staffType = JSON.parse(localStorage.getItem("user")).staffType;
    this.handleCurrentChange();
    // this.getStoreList()
  },
  computed: {
    ...mapState({
      storeList: (state) => state.tagsView.storeList,
    }),
  },
  watch: {
    // xcxStaffId() {
    //   console.log(this.xcxStaffId);
    // },
  },
  methods: {
    // 表格切换页面
    handleCurrentChange() {
      if (this.valueTime && this.valueTime.length) {
        this.dateType = "";
      } else if (!this.dateType) {
        this.dateType = "01";
      }
      if (this.isThan == false && this.valueTime) {
        this.tableSHowThan = false;
      } else {
        this.tableSHowThan = true;
      }
      if (this.dateType) {
        this.tableSHowThan = true;
      }
      const baseRequest = {
        dateType: this.dateType, //	今日 01 本周：03，本月：05
        endTime: this.valueTime ? this.valueTime[1] : "",
        orderType: this.orderType, //	排序类别 累计询价（报价）排行：01 累计成交排行：02，毛利贡献排行：03
        startTime: this.valueTime ? this.valueTime[0] : "",
        thanEndTime: this.thanValueTime ? this.thanValueTime[1] : "",
        thanStartTime: this.thanValueTime ? this.thanValueTime[0] : "",
        companyId: this.id,
        ...this.formData,
      };
      this.phoneTableLoading = true;
      this.searchLoading = true;
      _api.getRenewSoundStore(baseRequest).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data;
        }
        this.phoneTableLoading = false;
        this.searchLoading = false;
      });
    },
    addTimePicker() {
      this.addTime = true;
      this.addTimeText = "对比时间";
      this.isThan = true;
    },
    addTimeCancle() {
      this.addTimeText = "+添加对比时间";
      this.addTime = false;
      this.isThan = false;
      this.tableSHowThan = false;
      this.thanValueTime = [];
    },
    exportExcel() {
      this.exportExcelLoading = true
      _api
        .getRenewSoundStoreExcel({
          dateType: this.dateType, //	今日 01 本周：03，本月：05
          endTime: this.valueTime ? this.valueTime[1] : "",
          orderType: this.orderType, //	排序类别 累计询价（报价）排行：01 累计成交排行：02，毛利贡献排行：03
          startTime: this.valueTime ? this.valueTime[0] : "",
          thanEndTime: this.thanValueTime ? this.thanValueTime[1] : "",
          thanStartTime: this.thanValueTime ? this.thanValueTime[0] : "",
          companyId: this.id,
          ...this.formData,
        })
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);

          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
              }
            } catch (err) {
              const fileName = "换购成交数据门店统计.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
          this.exportExcelLoading = false
        })
        .catch((err) => {
          this.exportExcelLoading = false
          console.log(err);
        });
    },
    ClickDateType(val) {
      this.tableSHowThan = true;
      this.valueTime = "";
      this.dateType = val;
      this.handleCurrentChange();
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.el-date-time {
  /deep/ .el-input__inner {
    width: 250px !important;
  }
  margin: 0 20px;
  /deep/ .el-range-separator {
    width: 10% !important;
  }
}
.el-seach {
  margin-right: 50px;
  /deep/ .el-input--small .el-input__inner {
    height: 32px !important;
    line-height: 32px !important;
  }
  /deep/ .el-input__inner {
    width: 200px !important;
    border-radius: 18px !important;
  }
}

.phoneTable {
  .btn {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    margin: 0px 10px 0px 0;
    text-align: center;
    cursor: pointer;
  }
  .btnType1 {
    background: #0981ff;
    color: white;
    height: 32px;
    background: #20a0ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    padding: 0 9px;
  }
  .btnType-after1 {
    padding: 0 9px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #20a0ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #20a0ff;
    line-height: 32px;
  }
  .btnType2 {
    min-width: 30px;
    flex: 0.3;
    height: 36px;
    background: #0981ff;
    color: white;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType-after2 {
    // width: 33%;
    min-width: 40px;
    flex: 0.3;
    height: 36px;
    background: #ffffff;
    color: #0981ff;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType4 {
    width: 70px;
    // flex: 0.3;
    height: 36px;
    background: #0981ff;
    color: white;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType-after3 {
    width: 70px;
    // flex: 0.3;
    height: 36px;
    background: #ffffff;
    color: #0981ff;
    line-height: 36px;
    border-radius: 18px;
  }
  .btnType3 {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    text-align: center;
    cursor: pointer;
    width: 60px;
    height: 32px;
    line-height: 32px;
    background: #20a0ff;
    color: white;
    border-radius: 4px;
    margin-left: 20px;
    font-size: 14px;
  }
  .orderPagina {
    float: right;
    padding: 30px;
  }
  .numBG_1 {
    background-image: url("../../../../assets/images/imgAll/icon_01.png");
    background-size: 100%;
    color: white;
  }
  .numBG_2 {
    background-image: url("../../../../assets/images/imgAll/icon_02.png");
    background-size: 100%;
    color: white;
  }
  .numBG_3 {
    background-image: url("../../../../assets/images/imgAll/icon_03.png");
    background-size: 100%;
    color: white;
  }
  .el-button--default {
    //需要更改的按钮类型 type='default'
    background: #f9fbfd !important;
    border-color: #d9e6ff !important;
    color: #8492a6 !important;
  }
  //移入时按钮样式type='default'
  .el-button--default:hover {
    background: #fff !important;
    border-color: #45a3fc !important;
    color: #20a0ff !important;
    opacity: 0.8;
  }
  .el-button--primary {
    //需要更改的按钮类型 type='primary'
    background: #20a0ff !important;
    border-color: #20a0ff !important;
  }
  //移入时按钮样式 type='primary'
  .el-button--primary:hover {
    background: #20a0ff !important;
    border-color: #20a0ff !important;
    color: #fff !important;
    opacity: 0.8;
  }
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; //控制行数
    overflow: hidden;
  }
  .table_empty {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img-el-heard {
    display: flex;
    justify-content: center;
    img {
      margin: 0px 0 -3px 3px;
    }
  }
  .equationBlue {
    color: #20a0ff;
  }
  .equationGreen {
    color: #49a915;
  }
  .equationRed {
    color: #ff4949;
  }
}
</style>
